.selectWrapper {
  border: 1px solid var(--text-primary-color);
  margin-bottom: 0.5rem;
}

.selectWrapper .select {
  color: var(--text-primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 0.5rem 3rem 0.5rem 1rem !important;
}

.expandIcon {
  fill: var(--text-primary-color);
  right: 0.5rem;
}

.option .flagIcon {
  padding-right: 0.5rem;
}

.list .option {
  align-items: center;
  color: var(--text-primary-color);
  display: flex;
  flex-direction: row;
}
