@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

p {
  font-size: 1rem;
  margin: 0;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

button {
  font-family: inherit;
}

.small {
  font-size: 0.8em;
}

@media (max-width: 1200px) {
  html {
    font-size: 12px; /* original value 16px */
  }
}

@media (max-width: 992px) {
  html {
    font-size: 10px; /* original value 15px */
  }
}

@media (max-width: 768px) {
  html {
    font-size: 10px; /* original value 14px */
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 10px; /* original value 12px */
  }
}
