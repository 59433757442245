
:root {
  --background-color-main: #333;
  --background-color-secondary: #222;
  --text-primary-color: #fff;
  --text-secondary-color: #e4e2e5;
  --text-terciary-color: #ccc9ce;
  --color-warning: #f24141;
  --color-success: #1bda1b;
  --color-active: #7781e9;

  /* Overridable css for each config */
  --headerBackgroundColor: #222;
  --headerTextColor: #fff;
  /* --headerBackgroundImage: url("https://payments.bettingbeast.com/images/brand/background-grid.png"); */
}
